import React from 'react';
// components
import { Container } from './components/Container';
import { Section } from './components/Section';
// import { ResetCSS } from './components/Global';
// mock data
import { default as data } from './mock/data.json';

const App = () => {
    return (
        <Container>
            {/* <ResetCSS /> */}
            <Section data={data} />
        </Container>
    );
};

export default App;
