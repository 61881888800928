export const getFieldsContent = (fields, fieldsList) =>
    fieldsList.reduce((items, id) => {
        if (!fields[id]) return items;
        return [...items, fields[id]];
    }, []);

export const getActiveContent = (data, activeId) => {
    const menus = data['menu'];
    const fields = data['field'];
    const activeMenu = menus[activeId];
    const { type, content } = activeMenu;
    let nestedContent = false;

    if (type === 'menu') {
        nestedContent = content.reduce(
            (items, id) => [...items, data[type][id]],
            []
        );
    } else if (type === 'form') {
        nestedContent = {
            ...data[type][content],
            fields: getFieldsContent(fields, data[type][content].fields),
        };
    }

    return {
        ...activeMenu,
        content: nestedContent,
    };
};
