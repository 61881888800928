export const FONT_FAMILY = {
    PRIMARY: 'Roboto',
    SECONDARY: 'Arial, Helvetica, sans-serif',
};

export const FONT_WEIGHT = {
    LG: 300,
    RG: 400,
    SB: 500,
    BD: 700,
};
