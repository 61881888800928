// mock data
import server from '../mock/server';
// constants
import { URLS } from '../constants/forms';

server.post(URLS.MOCK, { status: 200 });

export const sendFormData = (url, data) =>
    fetch(url, {
        method: 'POST',
        body: data,
    })
        .then((res) => res.json())
        .catch((e) => console.log(e));
