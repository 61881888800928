import { css } from 'styled-components';
// constants
import { COLORS } from '../../constants/colors';
// import { BREAKPOINTS } from '../../constants/devices';
import { FONT_FAMILY, FONT_WEIGHT } from '../../constants/fonts';

const menu = css`
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: center;
`;

const menuItem = css`
    display: inline-block;
    margin: 5px;
    vertical-align: bottom;
`;

const menuButton = css`
    // background-color: ${COLORS.PRIMARY};
    // border-radius: 0;
    // border: none;
    // outline: none;
    // transition: 0.2s all;
    cursor: pointer;
    display: block;
    font-family: ${FONT_FAMILY.PRIMARY};
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.SB};
    padding: 10px 20px;
    text-align: center;
    width: 100%;

    // &:hover {
    //     background-color: ${COLORS.SECONDARY};
    //     color: white;
    // }
`;

export default {
    menu,
    menuItem,
    menuButton,
};
