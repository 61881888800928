import { css } from 'styled-components';
// constants
import { COLORS } from '../../constants/colors';
import { BREAKPOINTS } from '../../constants/devices';
import { FONT_FAMILY, FONT_WEIGHT } from '../../constants/fonts';

const fieldHeight = '40px';
const fieldHeightMD = '50px';

export const formContainer = css`
    background-color: ${COLORS.LIGHT_GREY};
    border: 1px solid ${COLORS.GREY};
    margin: 0 auto;
    max-width: 600px;
    padding: 20px 10px;
    text-align: center;

    @media ${BREAKPOINTS.MD} {
        padding: 30px;
    }
`;
export const formHeader = css`
    font-family: ${FONT_FAMILY.PRIMARY};
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.sb};
    text-align: center;
    margin: 0 0 15px 0;

    @media ${BREAKPOINTS.MD} {
        font-size: 1.25rem;
        margin: 0 0 20px 0;
    }
`;

export const input = css`
    background: white;
    border-radius: 0;
    border: 1px solid ${COLORS.GREY};
    box-sizing: border-box;
    color: ${COLORS.DARK_GREY};
    cursor: text;
    display: block;
    font-family: ${FONT_FAMILY.PRIMARY};
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.rg};
    height: ${fieldHeight};
    line-height: ${fieldHeight};
    margin: 0 0 10px 0;
    padding: 0 10px;
    position: relative;
    text-align: left;
    width: 100%;

    @media ${BREAKPOINTS.MD} {
        height: ${fieldHeightMD};
        line-height: ${fieldHeightMD};
        padding: 0 20px;
    }
`;

export const textarea = css`
    ${input}
    max-height: 300px;
    min-height: 100px;
`;

export const button = css`
    background-color: ${(props) =>
        props.type === 'submit' ? COLORS.PRIMARY : 'transparent'};
    border-radius: 4px;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-family: ${FONT_FAMILY.PRIMARY};
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.SB};
    padding: 10px 20px;
    text-align: center;
    transition: 0.2s all;
`;

export default {
    button,
    formContainer,
    formHeader,
    input,
    textarea,
};
