export const WIDTHS = {
    XS: '420px',
    SM: '768px',
    MD: '992px',
    LG: '1200px',
    XL: '1600px',
};

export const BREAKPOINTS = {
    XS: `(min-width: ${WIDTHS.XS})`,
    SM: `(min-width: ${WIDTHS.SM})`,
    MD: `(min-width: ${WIDTHS.MD})`,
    LG: `(min-width: ${WIDTHS.LG})`,
    XL: `(min-width: ${WIDTHS.XL})`,
};

export const CONTAINERS = {
    SM: '750px',
    MD: '970px',
    LG: '1140px',
    XL: '1500px',
};
