import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// styles
import styles from './styles';

const Menu = ({ items, onClick }) => {
    return (
        <MenuList>
            {Object.values(items).map((menuItem) => (
                <MenuItem key={`menu-item-${menuItem.id}`}>
                    <MenuButton onClick={() => onClick(menuItem)}>
                        {menuItem.title}
                    </MenuButton>
                </MenuItem>
            ))}
        </MenuList>
    );
};

const MenuList = styled.ul`
    ${styles.menu}
`;

const MenuItem = styled.li`
    ${styles.menuItem}
`;

const MenuButton = styled.button`
    ${styles.menuButton}
`;

Menu.defaultProps = {};
Menu.propTypes = {
    items: PropTypes.array,
    onClick: PropTypes.func,
};

export default Menu;
