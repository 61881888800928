import { css } from 'styled-components';
// constants
import { BREAKPOINTS } from '../../constants/devices';
import { FONT_FAMILY, FONT_WEIGHT } from '../../constants/fonts';

const backButton = css`
    cursor: pointer;
    display: block;
    font-family: ${FONT_FAMILY.PRIMARY};
    font-size: 0.875rem;
    font-weight: ${FONT_WEIGHT.SB};
    margin: 0 auto;
    max-width: 300px;
    padding: 5px 10px;
    text-transform: uppercase;
`;

const sectionContainer = css`
    margin: 30px 0;

    @media ${BREAKPOINTS.MD} {
        margin: 100px 0;
    }
`;

const sectionHeader = css`
    display: block;
    font-family: ${FONT_FAMILY.PRIMARY};
    font-size: 1.25rem;
    font-weight: ${FONT_WEIGHT.SB};
    margin: 0 0 20px 0;
    text-align: center;

    @media ${BREAKPOINTS.MD} {
        font-size: 2rem;
    }
`;

const sectionText = css`
    display: block;
    font-family: ${FONT_FAMILY.PRIMARY};
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.rg};
    text-align: center;
`;

export default {
    backButton,
    sectionContainer,
    sectionHeader,
    sectionText,
};
