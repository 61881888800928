const BLACK = '#000';
const BLUE = '#2693a6';
const DARK_GREY = '#222';
const GREEN = '#6ac94b';
const GREY = '#CCC';
const LIGHT_GREY = '#f2f2f2';

export const COLORS = {
    BLACK,
    BLUE,
    DARK_GREY,
    GREEN,
    GREY,
    LIGHT_GREY,
    PRIMARY: GREEN,
    SECONDARY: BLUE,
};
