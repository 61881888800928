import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// constants
import { BACK, TO_BE_IMPLEMENTED } from '../../constants/sections';
// components
import { Form } from '../../components/Form';
import { Menu } from '../../components/Menu';
// utils
import { getActiveContent } from '../../utils/content';
// styles
import styles from './styles';

const Section = ({ data }) => {
    const [activeContent, selectContent] = useState(0);
    const contentData = getActiveContent(data, activeContent);
    const { content, type, title, parent } = contentData;

    const goBack = () => {
        selectContent(parent);
    };
    const onMenuClick = (menuItem) =>
        menuItem.content
            ? selectContent(menuItem.id, menuItem.contentType)
            : alert(TO_BE_IMPLEMENTED);
    const renderContent = () => {
        if (type === 'form') {
            return <Form data={content} onCancel={goBack} />;
        } else if (type === 'menu') {
            return (
                <>
                    <Menu items={content} onClick={onMenuClick} />
                    {parent !== undefined && (
                        <BackButton onClick={goBack}>{BACK}</BackButton>
                    )}
                </>
            );
        } else {
            return <SectionText>{TO_BE_IMPLEMENTED}</SectionText>;
        }
    };

    return (
        <SectionContainer>
            <SectionHeader>{title}</SectionHeader>
            {renderContent()}
        </SectionContainer>
    );
};

const BackButton = styled.button`
    ${styles.backButton}
`;

const SectionContainer = styled.div`
    ${styles.sectionContainer}
`;

const SectionHeader = styled.h1`
    ${styles.sectionHeader}
`;

const SectionText = styled.p`
    ${styles.sectionText}
`;

// TODO: review this and update Form and Menu components
Menu.defaultProps = {};
Menu.propTypes = {
    data: PropTypes.shape({
        menu: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                title: PropTypes.string,
                content: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.array,
                ]),
                type: PropTypes.string,
            })
        ),
        form: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                title: PropTypes.string,
                url: PropTypes.string,
                fields: PropTypes.array,
            })
        ),
        field: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                placeholder: PropTypes.string,
                type: PropTypes.string,
            })
        ),
    }),
};

export default Section;
