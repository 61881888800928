import { css } from 'styled-components';
// constants
import { BREAKPOINTS, CONTAINERS } from '../../constants/devices';

export const container = css`
    margin: 0 auto;
    padding: 0 15px;

    @media ${BREAKPOINTS.SM} {
        width: ${CONTAINERS.SM};
    }
    @media ${BREAKPOINTS.MD} {
        width: ${CONTAINERS.MD};
    }
    @media ${BREAKPOINTS.LG} {
        width: ${CONTAINERS.LG};
    }
    @media ${BREAKPOINTS.XL} {
        width: ${CONTAINERS.XL};
    }
`;

export default {
    container,
};
