import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// constants
import { CANCEL, SUBMIT, WAIT } from '../../constants/forms';
// utils
import { sendFormData } from '../../utils/forms';
// styles
import styles from './styles';

const Form = ({ data, onCancel }) => {
    const [isLoading, toggleLoading] = useState(false);
    const { fields, title, url } = data;

    const getField = (field) => {
        const { type, placeholder, name } = field;

        switch (type) {
            case 'textarea':
                return (
                    <Textarea
                        key={`field-${field.id}`}
                        name={name}
                        placeholder={placeholder}
                    />
                );
            default:
                return (
                    <Input
                        key={`field-${field.id}`}
                        name={name}
                        placeholder={placeholder}
                        type={type}
                    />
                );
        }
    };

    const renderFields = () =>
        Object.values(fields).map((field) => getField(field));

    const onSubmit = (e) => {
        e.preventDefault();
        if (isLoading) return;

        toggleLoading(true);
        sendFormData(url, new FormData(e.target)).then(() => {
            toggleLoading(false);
        });
    };

    return (
        <FormContainer onSubmit={onSubmit}>
            <FormHeader>{title}</FormHeader>
            {renderFields()}
            <FormButton type="submit" title={SUBMIT}>
                {isLoading ? WAIT : SUBMIT}
            </FormButton>
            <FormButton type="cancel" title={CANCEL} onClick={() => onCancel()}>
                {CANCEL}
            </FormButton>
        </FormContainer>
    );
};

const Input = styled.input`
    ${styles.input}
`;
const Textarea = styled.textarea`
    ${styles.textarea}
`;
const FormContainer = styled.form`
    ${styles.formContainer}
`;
const FormHeader = styled.h3`
    ${styles.formHeader}
`;
const FormButton = styled.button`
    ${styles.button}
`;

Form.defaultProps = {};
Form.propTypes = {
    data: PropTypes.object,
    onCancel: PropTypes.func,
};

export default Form;
